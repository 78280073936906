<!--
 * @Author: luoyichen
 * @Date: 2023-08-14 14:27:00
 * @LastEditors: luoyichen
 * @LastEditTime: 2023-09-13 15:36:48
 * @FilePath: /src/websit/views/index/index.vue
 * @Description: MathX Download page
-->
<template>
  <div
    class="ctn"
    style="min-width: 1000px"
  >
    <div class="first-screen">
      <!-- 左边文字部分 -->
      <div class="content">
        <img
          class="logo"
          src="../../assets/logo.svg"
          alt="logo"
        >
        <div class="word">
          <img
            class="mathX"
            src="../../assets/mathX.png"
            alt="mathX"
          >
          <div class="describe">
            Unleash Your Math Superpowers<br>
            with Math-X
          </div>
        </div>
        <div class="action">
          <a
            target="_blank"
            href="https://apps.apple.com/app/id6450831536"
            class="button app-store"
          >
            <SvgIcon icon-class="apple" /><span>App Store</span>
          </a>
          <div class="qrCodeCtn">
            <img
              class="qrCodeButton"
              src="../../assets/qrCode.svg"
            >
            <div class="qrCodeBubble">
              <img
                class="qrCode"
                src="../../assets/mathXQrCode.png"
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 右边手机部分 -->
      <div class="phone" />
    </div>
    <div class="benefit">
      <div class="title">
        Join the Math-X Universe and discover
      </div>
      <div class="detail">
        <BenefitDetail
          :icon="require('../../assets/question.svg')"
          title="GPT4-Powered <br/> AI Solver"
        >
          Stuck on a tricky problem? <br>
          Math-X allows you to effortlessly solve<br>
          a broad scope of assignments, <br>including complex word problems!
        </BenefitDetail>
        <BenefitDetail
          :icon="require('../../assets/solve.svg')"
          title="Verified Textbook <br/> Solutions"
        >
          Rushing through math homework?<br>
          Math-X provides comprehensive, easy-<br>to-understand solutions straight from <br>your textbooks.
        </BenefitDetail>
        <BenefitDetail
          :icon="require('../../assets/24-7.svg')"
          title="Personal AI Tutor"
        >
          Still confused after reading the explanation?<br>
          Ask Professor X, your personal AI tutor, <br>
          available 24/7 to answer any of your <br>questions.
        </BenefitDetail>
        <BenefitDetail
          :icon="require('../../assets/role.svg')"
          title="Unique Math Characters"
        >
          Overwhelmed or bored with math?<br>
          Engage in enlightening chats with <br>diverse characters within the Math-X<br>
          Universe, and discover the joy of<br>
          learning math!
        </BenefitDetail>
      </div>
    </div>
    <div class="footer">
      <div><SvgIcon icon-class="mathX" /><span class="title">Math-X</span></div>
      <div class="cop">
        Math-X ©2023-2026
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgIcon from '@/components/SvgIcon/index.vue';
import BenefitDetail from './components/BenefitDetail.vue';
console.log('test');
</script>

<style scoped lang="less">
.ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.button {
  width: auto;
  height: 60px;
  border-radius: 11.284px;
  border: 2.821px solid #fff;
  background-color: rgba(255, 255, 255, 0);
  color: #fff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.first-screen {
  width: 100%;
  height: 1080px;
  background-image: url("../../assets/firstScreen.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 181.5px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 80px;

    .logo {
      width: 215px;
      height: 215px;
    }

    .word {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .mathX {
        width: 327.235px;
        height: 68.214px;
      }

      .describe {
        font-size: 36.35px;
        font-style: normal;
        font-weight: 600;
        line-height: 56.428px;
        /* 155.234% */
        letter-spacing: -0.364px;
        color: #fff;
      }
    }

    .action {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      .app-store {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 320px;
        gap: 8px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 24px;
      }

      .qrCodeCtn {
        width: 109px;
        height: 60px;
        box-sizing: border-box;
        border-radius: 11.284px;
        object-fit: cover;
        background-color: rgba(255, 255, 255, 0);
        transition: all 0.2s ease-in-out;
        position: relative;

        .qrCodeButton {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          object-fit: cover;
        }

        .qrCodeBubble {
          width: 176px;
          height: 183px;
          background-image: url("../../assets/bubble.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          box-sizing: border-box;
          position: absolute;
          top: -193px;
          right: 0px;
          padding: 6px 6px 13px 6px;
          display: none;
          opacity: 0;
          transition: all 0.2s ease-in;

          .qrCode {
            width: 164px;
            height: 164px;
            object-fit: cover;
          }
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);

          .qrCodeBubble {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }

  .phone {
    min-width: 590px;
    height: 910px;
    background-image: url("../../assets/phone.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-self: flex-end;
  }
}

.benefit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  .title {
    margin-top: 140px;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.6px;
    color: #1a1a1a;
  }

  .detail {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 80px;
    margin-bottom: 140px;
  }
}

.footer {
  width: 100%;
  height: 146px;
  background: #1a1a1a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 28px;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;

  .title {
    margin-left: 14px;
  }

  .cop {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
  }
}
</style>
